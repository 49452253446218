import React, { useContext } from 'react'

import TextContext from '../../contexts/text'

const Home = () => {
  const { text } = useContext(TextContext)

  return (
    <div>
      {text.intro?.map((p, index) => <p key={index} className="center">{p}</p>)}
    </div>
  )
}

export default Home