import React, { useContext } from "react"
import { Link } from "react-router-dom"

import TextContext from "../../contexts/text"

import Logo from '../../assets/logo.png'
import Instagram from '../../assets/instagram.png'
import Facebook from '../../assets/facebook.png'
import Phone from '../../assets/phone.png'
import Email from '../../assets/email.png'

import './header.scss'

const Header = () => {
	const { text } = useContext(TextContext)
	
  	return (
		<header>
			<div>
				<Link to="/">
					<img src={Logo} alt="designnooraperala" />
				</Link>

				<ul>
					<li><a title="Instagram" href="https://www.instagram.com/designnooraperala" target="_blank" rel="noreferrer"><img src={Instagram} alt="instagram" /></a></li>
					<li><a title="Facebook" href="https://www.facebook.com/designnooraperala" target="_blank" rel="noreferrer"><img src={Facebook} alt="facebook" /></a></li>
					<li><a title="Soita" href={"tel:" + text.phone}><img src={Phone}alt="puh" /></a></li>
					<li><a title="Sähköposti" href={"mailto:" + text.email}><img src={Email} alt="email" /></a></li>
				</ul>
			</div>
		</header>
  	)
}

export default Header