import React from 'react'
import { Link, useHistory } from "react-router-dom"

import './notfound.scss'

function Error() {
  let history = useHistory();

  return (
    <div className="error">
      <p>Etsimääsi sivua ei löytynyt. Se on voitu siirtää tai poistaa.</p>
      <p>Palaa <button onClick={history.goBack}>takaisin</button> tai mene <Link to="/">etusivulle</Link>.</p>
    </div>
  )
}

export default Error