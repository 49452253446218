import React from 'react'
import { Link } from 'react-router-dom'

import './thumbnail.scss'

const Thumbnail = ({category, image}) => (
    <li key={image.name} className={image.span === "2" ? "medium" : image.span === "4" ? "big" : null}>
        <Link to={"/portfolio/" + category + "/" + image.name + "." + image.extension}>
            <img src={"/portfolio/" + category + "/" + image.name + "_." + image.extension} alt={image.name} />
        </Link>
    </li>
)

export default Thumbnail