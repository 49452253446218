import React, { useContext } from "react"
import Nav from "../nav/nav"

import TextContext from '../../contexts/text'

import LogoW from '../../assets/logow.png'
import Instagram from '../../assets/instagram.png'
import Facebook from '../../assets/facebook.png'
import LinkedIn from '../../assets/linkedin.png'

import './footer.scss'

const Footer = () => {
	const { text } = useContext(TextContext)

	if (typeof text.phone !== "undefined") {
		text.phone_ = text.phone?.replace(/^(\d{3})/,'$1 ')
	}

	return (
		<footer>
			<div className="logo">
				<img src={LogoW} alt="" />
			</div>

			<div className="sitemap">
				<h2>Sivusto</h2>
				<Nav />
			</div>

			<div className="info">
				<h2>Yhteystiedot</h2>
				<ul>
					<li><a className="m" href={"mailto:" + text.email}>{text.email}</a></li>
					<li><a className="p" href={"tel:" + text.phone}>{text.phone_}</a></li>
				</ul>

				<ul className="some">
					<li><a title="Instagram" href="https://www.instagram.com/designnooraperala" target="_blank" rel="noreferrer"><img src={Instagram} alt="" />Instagram</a></li>
					<li><a title="Facebook" href="https://www.facebook.com/designnooraperala" target="_blank" rel="noreferrer"><img src={Facebook} alt="" />Facebook</a></li>
					<li><a title="LinkedIn" href="https://fi.linkedin.com/in/noora-per%C3%A4l%C3%A4-202161102" target="_blank" rel="noreferrer"><img src={LinkedIn} alt="" />LinkedIn</a></li>
					<li><a title="Shutterstock" href="https://www.shutterstock.com/g/nooraperala" rel="noreferrer" target="_blank">Shutterstock</a></li>
				</ul>
			</div>
			
			<span>&copy; {text.name} {new Date().getFullYear()}. Kaikki oikeudet pidätetään.</span>
		</footer>
	)
}

export default Footer