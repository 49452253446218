import React, { useContext } from 'react'

import TextContext from '../../contexts/text'

import Avatar from '../../assets/avatar.jpg'

import './contact.scss'

const Contact = () => {
  const { text } = useContext(TextContext)

  if (typeof text.phone !== "undefined") {
		text.phone_ = text.phone?.replace(/^(\d{3})/,'$1 ')
	}

  return (
    <div className="contact">
        <div>
          <img src={Avatar} alt="" />
          <div>
            <ul>
                <li>{text.name}</li>
                <li><a className="m" href={"mailto:" + text.email}>{text.email}</a></li>
                <li><a className="p" href={"tel:" + text.phone}>{text.phone_}</a></li>
            </ul>
          </div>
        </div>

        {text.contact?.paragraph?.map((p, index) => <p key={index}>{p}</p>)}
    </div>
  )
}

export default Contact