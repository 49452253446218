import React from 'react'

import SubNav from '../../../structure/nav/subnav/subnav'
import Thumbnail from '../thumbnail/thumbnail'
import Ad from '../ad/ad'

import './imagelist.scss'

const ImageList = ({ selectedDirectory, ad, children, ...otherProps }) => {
    return (
        <div className="gallery">
            <ul className="nav">
                <SubNav />
            </ul>

            <ul className="imagelist">
                {
                    ad ? <Ad {...otherProps} /> : null
                }

                {
                    selectedDirectory.map((image, index) => (
                        <Thumbnail key={index} image={image} {...otherProps} />
                    ))
                }
            </ul>

            {children || null}
        </div>
    )
}

export default ImageList