import { createContext, useEffect, useState } from 'react'

const TextContext = createContext()

export const TextProvider = ({ children }) => {
    const [text, setText] = useState()
    const [isLoaded, setIsLoaded] = useState(false)

    const fetchText = async () => {
        const data = await fetch("/api/data")
        const text = await data.json()
        setText(text.data)
        setIsLoaded(true)
    }

    useEffect(() => {
        fetchText()
    }, [])

    return (
        <TextContext.Provider value={{text, isLoaded}}>
            {children}
        </TextContext.Provider>
    )
}

export default TextContext