import './loading.scss'

export const LoadingSpin = ({ isLoaded, children }) => {
    if (!isLoaded) return <div className="loading_spin"></div>
    return children
}

export const LoadingDots = ({ isLoaded, children }) => {
    if (!isLoaded) return <div className="loading_dots"><div></div><div></div><div></div><div></div></div>
    return children
}