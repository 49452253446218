import React, { useContext } from 'react'

import TextContext from '../../../contexts/text'

import './ad.scss'

const Ad = ({ category }) => {
    const { text } = useContext(TextContext)

    return (
        <li className="ad">
            <a href="https://www.shutterstock.com/g/nooraperala" rel="noreferrer" target="_blank">
                <img src={"/portfolio/" + category + ".jpg"} alt={category} />
            </a>

            <div>
                {text.gallery?.ad?.map((item, index) =>
                    <span key={index} >{item}</span>
                )}
            </div>
        </li>
    )
}

export default Ad