import React, { useContext, useEffect } from 'react'
import { BrowserRouter, Switch, Route, Redirect, useHistory } from 'react-router-dom'

import Header from './structure/header/header'
import Divider from './structure/divider/divider'
import Nav from './structure/nav/nav'
import Footer from './structure/footer/footer'

import HomePage from './components/homepage/homepage'
import Gallery from './components/gallery/gallery'
import Services from './components/services/services'
import Contact from './components/contact/contact'

import NotFound from './components/notfound/notfound'
import { LoadingSpin } from './components/loading/loading'

import FilesContext from './contexts/files'
import TextContext from './contexts/text'

import './App.scss'

// Google analytics
import ReactGA, { ga } from 'react-ga'
ReactGA.initialize('G-0PFPFBB6J1')
ReactGA.pageview(window.location.pathname + window.location.search)
//

const Routes = () => {
  const { isLoaded: filesIsLoaded, categories } = useContext(FilesContext)

  // Google analytics
  const history = useHistory()
  console.log(history)
  useEffect(() => {
    trackPageView()
    history.listen(trackPageView)
  }, [history])

  function trackPageView() {
    ga("set", "page", window.location.pathname)
    ga("send", "pageview")
  }
  //

  return (
    <Switch>
      <Route exact path="/portfolio/">
        {filesIsLoaded ? <Redirect to={"/portfolio/" + categories[0][1]} /> : null}
      </Route>

      <Route path="/portfolio/:category?/:image?">
        <Gallery />
      </Route>

      <Route exact path="/hinnasto/">
        <Services />
      </Route>

      <Route exact path="/yhteystiedot/">
        <Contact />
      </Route>

      <Route exact path="/">
        <HomePage />
      </Route>

      <Route>
        <NotFound />
      </Route>
    </Switch>
  )
}

const App = () => {
  const { isLoaded: textIsLoaded } = useContext(TextContext)

  return (
    <LoadingSpin isLoaded={textIsLoaded}>
      <BrowserRouter>
        <div className="wrapper">
          <Header />
          <Nav menu />
          <Divider />
          <main id="main">
            <Routes />
          </main>
        </div>
        <Footer />
      </BrowserRouter>
    </LoadingSpin>
  )
}

export default App
