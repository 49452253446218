import React from 'react'
import { NavLink } from 'react-router-dom'

import SubNav from './subnav/subnav'

import MenuIcon from '../../assets/menu.svg'

import './nav.scss'

const Nav = ({menu}) => {
	function scrollTop() {
		return document.getElementById("menu").checked === false ? window.scrollTo({ top: 0, behavior: 'smooth' }) : null
	}

	function scrollMain() {
		return !menu ? document.getElementById("main").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" }) : null
	}

	function toggleMenu() {
		return document.getElementById("menu") ? document.getElementById("menu").checked = false : null
	}

	return (
		<nav>
			{menu ? <input type="checkbox" id="menu" /> : null}
			{menu ? <label htmlFor="menu" onClick={() => scrollTop()}><img src={MenuIcon} alt="&#9776;" /></label> : null}
			<ul onClick={() => {
				toggleMenu()
				scrollMain()
			}}>
				<li><NavLink to="/portfolio/" className="page" activeClassName="current" onClick={() => scrollMain()}>portfolio</NavLink>
					<ul onClick={() => scrollMain()}>
						<SubNav />
					</ul>
				</li>
				<li><NavLink exact to="/hinnasto/" className="page" activeClassName="current" onClick={() => scrollMain()}>hinnasto</NavLink></li>
				<li><NavLink exact to="/yhteystiedot/" className="page" activeClassName="current" onClick={() => scrollMain()}>yhteystiedot</NavLink></li>
			</ul>
		</nav>
	)
}

export default Nav