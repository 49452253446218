import React from 'react'
import { Link, useHistory } from "react-router-dom"

import useKeypress from './keypress'

import './modal.scss'

const Modal = ({ category, image, description, nextImage, previousImage }) => {
    let history = useHistory()

    useKeypress('ArrowLeft', () => {
        if (previousImage !== null) {
            history.push(previousImage)
        }
    })

    useKeypress('ArrowRight', () => {
        if (nextImage !== null) {
            history.push(nextImage)
        }
    })

    useKeypress('Escape', () => {
        if (previousImage !== null || nextImage !== null) {
            history.push("/portfolio/")
        }
    })

    return (
        <div className="modal">
            <Link className="mask" to={"/portfolio/" + category + "/"}><span /></Link>

            {previousImage ? <Link className="arrow left" to={"/portfolio/" + category + "/" + previousImage} tabIndex="1" title="Edellinen kuva">&#10216;</Link> : null}

            <Link className="image" to={"/portfolio/" + category + "/"}>
                <img id="image" src={process.env.PUBLIC_URL + "/portfolio/" + category + '/' + image} alt="Kuvaa ei jostain syystä voitu ladata." />
                {description ? <span>{description}</span> : null}
            </Link>

            {nextImage ? <Link className="arrow right" to={"/portfolio/" + category + "/" + nextImage} tabIndex="2" title="Seuraava kuva">&#10217;</Link> : null}
        </div>
    )
}

export default Modal