import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import stringReplace from 'react-string-replace'

import TextContext from '../../contexts/text'

import './services.scss'

const l = str => {
    return stringReplace(str, /(<.*?>)/, (match, i) => (<Link key={i} to="/yhteystiedot/">{match.replace("<", "").replace(">", "")}</Link>))
}

const Services = () => {
  const { text: { services: text }} = useContext(TextContext)

  return (
    <div className="services">
      <h1>
        <span>{l(text.portrait?.name)}</span>
        <span>{l(text.portrait?.disc)}</span>
      </h1>

      <p>{l(text.portrait?.paragraph[0])}</p>
      <p>{l(text.portrait?.paragraph[1])}</p>

      {text.portrait?.categories.map((group, index) => (
        <div key={index}>
          <h2>{l(group.name)}</h2>
          <ul>
            {group.items.map((item, index) => (
              <li key={index}>
                {l(item.name)}
                <span className="price">{l(item.price)}</span>
              </li>
            ))}
          </ul>
        </div>
      ))}

      <p className="ob">{l(text.portrait?.paragraph[2])}</p>

      {text.portrait?.widecategories?.map((group, index) => (
        <div key={index} className="wide">
          <h2>{l(group.name)} {l(group.items[0].price)}</h2>
          <p>{l(group.items[0].text)}</p>
        </div>
      ))}

      <h1>
        <span>{l(text.design?.name)}</span>
        <span>{l(text.design?.disc)}</span>
      </h1>
      
      {text.design?.categories?.map((group, index) => (
          <div key={index}>
            {group.items?.map((item, index) => (
                <div key={index}>
                  <h3>{l(item.name)} <span className="price">{l(item.price)}</span></h3>
                  <ul>
                    {item.text.map((text, index) =>
                      <li key={index}>{l(text)}</li>
                    )}
                  </ul>
                </div>
              )
            )}
          </div>
        )
      )}

      {text.design?.paragraph?.map((p, index) =>
        <p key={index}>{l(p)}</p>
      )}
    </div>
  )
}

export default Services