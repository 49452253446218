import React, { useContext } from 'react'
import { NavLink } from "react-router-dom"

import FilesContext from '../../../contexts/files'

const SubNav = () => {
    const { categories } = useContext(FilesContext)

    return (
        categories.map((dir, index) => (
            <li key={index}><NavLink to={"/portfolio/" + dir[1] + "/"} className="page" activeClassName="current">{dir[0]}</NavLink></li>
        ))
    )
}

export default SubNav