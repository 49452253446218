import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import ImageList from './imagelist/imagelist'
import Modal from './modal/modal'
import NotFound from '../notfound/notfound'

import FilesContext from '../../contexts/files'

import './gallery.scss'

const Images = () => {
  const { files, categories } = useContext(FilesContext)
  let selectedCategory = useParams().category
  let selectedImage = useParams().image

  console.log(files)

  if (!categories.find(dir => dir[1] === selectedCategory)) {
    return <NotFound />
  }

  let { children: selectedDirectory, ad } = files.find(dir => dir.name === selectedCategory)

  const getIndex = value => {
    if (selectedDirectory.length < 2) {
      return null
    }

    let target = selectedDirectory.findIndex(image => (image.name + "." + image.extension) === selectedImage) + value

    if (target < 0) target = selectedDirectory.length - 1
    if (target >= selectedDirectory.length) target = 0

    return selectedDirectory[target].name + "." + selectedDirectory[target].extension
  }

  return (
    selectedImage ?
      selectedDirectory.find(image => image.name + "." + image.extension === selectedImage) ? (
        <ImageList selectedDirectory={selectedDirectory} ad={ad} category={selectedCategory}>
          <Modal
            category={selectedCategory}
            image={selectedImage}
            description={selectedDirectory.find(image => image.name + "." + image.extension === selectedImage).desc}
            previousImage={getIndex(-1)}
            nextImage={getIndex(1)}
          />
        </ImageList>
      ) : <NotFound />
    : <ImageList selectedDirectory={selectedDirectory} ad={ad} category={selectedCategory} />
  )
}

export default Images