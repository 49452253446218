import { createContext, useEffect, useState } from 'react'

const FilesContext = createContext()

export const FilesProvider = ({ children }) => {
    const [files, setFiles] = useState()
    const [categories, setCategories] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)

    const fetchFiles = async () => {
        const data = await fetch("/api/files")
        const files = await data.json()
        setFiles(files.files)
        
        let categories = []
        files.files.map(dir =>
            categories.push([dir.long || dir.name, dir.name])
        )

        setCategories(categories)
        setIsLoaded(true)
    }

    useEffect(() => {
        fetchFiles()
    }, [])

    return (
        <FilesContext.Provider value={{files, categories, isLoaded}}>
            {children}
        </FilesContext.Provider>
    )
}

export default FilesContext